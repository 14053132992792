.message__form {
  max-width: 100%;
}
.inputIconWrap {
  position: relative;
}
.inputIconWrap .iconBox {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
  cursor: pointer;
}
.iconBox {
  width: 24px;
  height: 24px;
}
.iconBox svg {
  fill: #858585;
}
.emoji-mart  {
  position: absolute;
  bottom: 74px;
  right: 1rem;
}
.pickerOverlay {
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5)
}

@media screen and (max-width: 768px) {
  .message__form {
    max-width: 95%;
  }
}

@media screen and (max-width: 400px) {
  .emoji-mart {
    width: 310px;
  }
}