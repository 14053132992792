/* Main CSS for Messages */
.messagesContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.messagesHeader {
  height: 60px;
  flex: 0 0 60px;
}
.messagesBody {
  flex: 1 1;
  max-height: calc(100% - 60px - 74px);
  height: 100%;
  padding-top: 20px;
  padding-right: 0.25rem;
}

/* Messages Header */
.chatPanelHeaderBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Messages.js */
.messagesHolderForScroll {
  height: 100%;
  overflow-y: auto;
  position: relative;
}
.messages {
  max-width: 100% !important;
  height: 100%;
  padding-right: 0.5rem;
}
.comment {
  padding-right: 0.25rem;
}

/* MessageForm.js */
.message__form {
  flex: 0 0 74px;
  padding: 1.25rem 0;
  padding-right: 1rem;
  height: 74px;
  background-color: #ffffff;
  margin-top: auto;
  position: relative;
}

.emoijpicker {
  position: absolute;
}



/* round scroll bar tracking */
.messagesBody::-webkit-scrollbar-track {
  border-radius: 5px !important;
}
.messagesHolderForScroll::-webkit-scrollbar-track {
  border-radius: 5px !important;
}