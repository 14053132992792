.metaPanelContainer {
  height: 100%;
  overflow: auto;
  max-width: 450px;
}
.metaPanelHeader {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 1rem;
  padding-right: 1rem;
}
.metaPanelHeaderAction {
  flex: 0 0 36px;
  height: 36px;
}
.closeMetaPanelIcon {
  width: 36px;
  height: 36px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
}
.metaPanelContainer .metaImages {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}
.metaPanelContainer .metaImages img {
  width: 90%;
}