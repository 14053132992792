.ui.vertical.menu .menu .item {
  font-size: 1em !important;
}

/* Modal Style */
.inputButtonWrapper {
  width: 100%;
  padding: 1rem;
  padding-bottom: 0;
  display: flex;
  align-items: center;
}
.inputField {
  flex: 1 1;
  width: 100%;
}
.inputButton {
  flex: 0 0;
  min-width: 100px;
  text-align: right;
}
.channelBox {
  position: relative;
}
.leaveChannelButtonBox {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: rgba(255,255,255,.5);
}
.roomMenuOverlay {
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
  z-index: 22;
}
.roomMenu {
  position: absolute;
  width: 175px;
  height: auto;
  border: 1px solid #e9e8eb;
  border-radius: 5px;
  left: 70%;
  top: 10px;
  background-color: #ffffff;
  z-index: 23;
}
.roomMenuItem {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  cursor: pointer;
}
.roomMenuItem:hover {
  background-color: rgba(0,0,0,0.05);
}
.roomMenu h3 {
  color: black;
}

.modalFooter {
  border-top: 1px solid #e9e8eb;
}

@media screen and (max-width: 767px) {
  .inputButtonWrapper {
    padding-bottom: 1rem;
  }
}


@media screen and (max-width: 768px) {
  .ui.vertical.menu .menu .item {
    font-size: 1.05em !important;
  }
}
