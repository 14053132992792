.sidePanel {
  flex: 0 0;
  max-width: 0;
  overflow-x: hidden;
  position: relative;
}
.sidePanel.is-active {
  max-width: 100%;
  flex: 1 1;
  overflow-x: visible !important;
}
.sidePanelContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  left: 0;
}
.logoContainer {
  flex: 0 0;
  background-color: #090c08;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidePanelAction {
  flex: 0 0 36px;
  width: 36px;
  height: 36px;
  border: 1px solid #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
}
.sidePanelMenu {
  flex: 1 1;
}

.sidePanelOverlay {
  flex: 0 0;
}

.sidePanelLoader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
}
.sidePanelLoader.active {
  display: block;
}

@media screen and (max-width: 768px) {
  .sidePanel.is-active .sidePanelOverlay {
    flex: 1 1;
    cursor: pointer;
  }
  .sidePanel {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 101;
    left: -100%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    transition: all 200ms;
  }
  .sidePanel.is-active {
    left: 0%;
  }
}

/* edit Profile Modal */
.ui.basic.modal.editProfileModal {
  background-color: #ffffff;
  border: 1px solid #e9e8eb;
  border-radius: 5px;
  color: black;
}

.ui.basic.modal.editProfileModal .header {
  color: black;
}

.avatarInputWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
}
.avatarInputWrapper .avatarInput {
  flex: 1 1;
  width: 100%;
  height: 36px;
}
.inputLabelImageWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.inputLabel + .avatarImage {
  margin-left: 1rem;
}
.avatarImage {
  flex: 0 0 74px;
  width: 74px;
  height: 74px;
  border-radius: 100px;
}
.inputLabelWrapper {
  margin-bottom: 2rem;
}
.inputLabel {
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 18px;
}

.avatarImage + .avatarInput {
  margin-left: 1rem;
}
.avatarInputWrapper .ui.input > input {
  padding: 0.4rem 1rem;
}

/* edit profile modal styles END */
