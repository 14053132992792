html,
body,
.app {
  position: fixed;
  top: 0;
  max-height: 100vh;
  width: 100vw;
  background: #f5fcff;
  display: flex;
  overflow: auto;
}

/* Meta Panel Styles */
.metaPanel {
  flex: 0 1;
  border-left: 1px solid rgb(204, 204, 204);
  background-color: #ffffff;
  max-width: 0;
  overflow: hidden;
}
.metaPanel.is-active {
  max-width: 100%;
  overflow: unset;
}


.chatPanel {
  flex: 1 1 100%;
  padding: 0;
}
.metaPanel {
  padding: 0;
}

.intro {
  font-family: Roboto,sans-serif;
  font-weight: 700;
  line-height: 28px;
  font-size: 20px;
}

/* SidePanel.js */
.menu {
  padding-bottom: 1em;
}

@media screen and (max-width: 768px) {
  .message__form {
    left: 1em;
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .message__form {
    left: 1em;
    margin-left: 0;
  }
}

/* Message.js */
.message__self {
  border-left: 2px solid orange;
  padding-left: 8px;
}

.message__image {
  padding: 1em;
}

/* ProgressBar.js */
.progress__bar {
  margin: 0.3em 0 0 0 !important;
}

/* ColorPanel.js */
.color__container {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 35px;
  border-radius: 3px;
}

.color__square {
  width: 35px;
  height: 35px;
}

.color__overlay {
  width: 85px;
  height: 35px;
  transform: rotate(225deg);
}

/* Typing.js */
.user__typing {
  font-style: italic;
  font-weight: bold;
  margin-right: 3px;
}

.typing {
  width: 5em;
  height: 2em;
  position: relative;
  padding: 10px;
  margin-left: 5px;
  background: #e6e6e6;
  border-radius: 20px;
}

.typing__dot {
  float: left;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background: #8d8c91;
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

/* Skeleton.js */
.skeleton {
  position: relative;
  overflow: hidden;
  height: 40px;
  margin-bottom: 10px;
}

.skeleton::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  animation: sweep 2s infinite;
  background-image: linear-gradient(
    to left,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
}

@keyframes sweep {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(150%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.skeleton__avatar {
  height: 35px;
  width: 35px;
  border-radius: 3px;
  background-color: rgba(58, 57, 57, 0.3);
}

.skeleton__author {
  background-color: rgba(58, 57, 57, 0.3);
  width: 120px;
  height: 10px;
  border-radius: 3px;
  position: absolute;
  bottom: 30px;
  left: 40px;
  right: 0;
}

.skeleton__details {
  background-color: rgba(58, 57, 57, 0.3);
  height: 20px;
  border-radius: 3px;
  position: absolute;
  bottom: 5px;
  left: 40px;
  right: 20px;
}


/* Mobile Styles */
.is_for_mobile {
  display: none !important;
}

@media screen and (max-width: 1280px) {
  .app {
    position: relative;
  }
  .metaPanel {
    position: absolute;
    top: 0; right: 0; bottom: 0;
    max-width: 0;
    padding: 0;
    overflow-x: hidden;
  }
  .metaPanel.is-active {
    max-width: 100%;
    width: auto;
  }
}
@media screen and (max-width: 768px) {
  .is_for_mobile {
    display: flex !important;
  }
}
