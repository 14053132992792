.messageHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.leftHeader {
  display: flex;
  align-items: center;
}
.roomIcon {
  height: 48px;
  width: 48px;
  border-radius: 5px;
  font-size: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  margin-right: 1rem;
  cursor: pointer;
}
.actionIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid rgb(204, 204, 204);
  padding: 0.5rem;
  padding-top: 0.25rem;
  border-radius: 5px;
}
.actionIcon:hover {
  background-color: #f7f7f7;
}
.actionIcon p {
  font-size: 14px;
  margin-top: 0.25rem;
}
.functionIcon:hover {
  transform: scale(1.05);
  transition: transform 200ms;
}